<template>
  <div class="root-page app-page">

    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>

    <MainBar></MainBar>

    <b-modal id="share-with-audience-box" :title="shareBoxTitle" hide-footer
             v-if="entity || folder">
      <ShareWithAudienceBox :content="entity" :audience="audience" :shareWithMultiple="shareWithMultiple"
                            :shareFolder="shareFolder" :folder="folder"
                            :sharePlaylist="sharePlaylist" :playlist="playlist"
                            v-on:onFinish="_onShareWithAudienceFinish"></ShareWithAudienceBox>
    </b-modal>

    <b-modal id="share-with-teams-box" size="lg" :title="$t('DashboardShowShareWithTeamButton')" hide-footer
             v-if="entity">
      <ShareWithTeamsBox :entity="entity" v-on:onFinish="_onShareWithTeamsFinish"></ShareWithTeamsBox>
    </b-modal>

    <b-modal id="entity-info-box" size="lg" :title="$t('Info')" hide-footer @hide="_onShowInfoClose"
             v-if="entity">
      <EntityInfoBox :entity="entity" v-on:onFinish="_onShowInfoFinish"></EntityInfoBox>
    </b-modal>

    <b-modal id="entity-translation-box" size="lg" :title="$t('Translate')" hide-footer no-close-on-backdrop
             v-if="entity">
      <EntityTranslationBox :entity="entity" v-on:onFinish="_onTranslateFinish"></EntityTranslationBox>
    </b-modal>

    <b-modal id="edit-content-file-box" size="lg" :title="$t('EditContentFile')" hide-footer
             @hidden="onEditContentFileBoxClose"
             v-if="entity">
      <EditContentFileBox :contentFile="entity"></EditContentFileBox>
    </b-modal>

    <b-modal id="edit-content-app-box" size="lg" :title="$t('EditContentApp')" hide-footer
             @hidden="onEditContentAppBoxClose"
             v-if="entity">
      <EditContentAppBox :contentApp="entity"></EditContentAppBox>
    </b-modal>

    <b-modal id="create-edit-folder-box" size="lg" :title="$t('EditContentFolder')" hide-footer
             @hidden="onCreateEditContentFolderBoxClose"
    >
      <CreateEditContentFolderBox :contentFolder="folder"
                                  v-on:onFinish="_onCreateEditContentFolderBoxFinish"></CreateEditContentFolderBox>
    </b-modal>

    <b-modal id="share-folder-with-teams-box" :title="$t('DashboardShowShareWithTeamButton')" hide-footer
             @hidden="onShareContentFolderWithTeamsBoxClose"
    >
      <ShareContentFolderWithTeamsBox :contentFolder="folder"
                                      v-on:onFinish="_onShareContentFolderWithTeamsBoxFinish"></ShareContentFolderWithTeamsBox>
    </b-modal>

    <b-modal id="introduction-box" hide-header hide-footer centered no-fade>
      <IntroductionBox></IntroductionBox>
    </b-modal>

    <b-modal id="update-box" size="lg" hide-header hide-footer centered no-close-on-backdrop no-close-on-esc>
      <UpdateBox></UpdateBox>
    </b-modal>

      <b-modal id="push-notifications-popup" size="lg" hide-header hide-footer centered>
          <PushNotificationsPopup></PushNotificationsPopup>
      </b-modal>


    <b-modal id="folder-info-box" size="lg" :title="$t('Info')" hide-footer v-if="folder">
      <FolderInfoBox :content-folder="folder" v-on:onFinish="_onShowFolderInfoFinish"></FolderInfoBox>
    </b-modal>

      <b-modal id="create-edit-playlist-box" size="lg" :title="$t('EditPlaylist')" hide-footer
               @hidden="onCreateEditPlaylistBoxClose"
      >
          <CreateEditPlaylistBox :playlist="playlist"
                                      v-on:onFinish="_onCreateEditPlaylistBoxFinish"></CreateEditPlaylistBox>
      </b-modal>

  </div>
</template>


<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import MainBar from "@/_view/menu/MainMenuBar.vue";
import EditContentFileBox from "@/content_file/_view/EditContentFileBox.vue";
import EntityInfoBox from "@/entity/_view/EntityInfoBox.vue";
import EditContentAppBox from "@/content_app/_view/EditContentAppBox.vue";
import EntityTranslationBox from "@/entity/_view/EntityTranslationBox.vue";
import UpdateBox from "@/_view/components/UpdateBox.vue";
import ShareContentFolderWithTeamsBox from "@/content/_view/ShareContentFolderWithTeamsBox.vue";
import CreateEditContentFolderBox from "@/content/_view/CreateEditContentFolderBox.vue";
import ShareWithTeamsBox from "@/entity/_view/ShareWithTeamsBox.vue";
import ShareWithAudienceBox from "@/content/_view/ShareWithAudienceBox.vue";
import FolderInfoBox from "@/content/_view/FolderInfoBox.vue";
import IntroductionBox from "@/help/introduction/_view/IntroductionBox.vue";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import AudienceModel from "@/audience/_model/AudienceModel";
import {EventBus} from "@/__libs/vue/EventBus";
import {EventBusActions, LocalStorageKey, Runtime} from "@/_model/app.constants";
import ContentListModel from "@/content/_model/ContentListModel";
import JsonUtil from "@/__libs/utility/JsonUtil";
import LocalStorageManager from "@/__libs/offline_storage/LocalStorageManager";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {TierType} from "@/project/_model/project.constants";
import AppModel from "@/_model/AppModel";
import {IDeepLinkParamsDto} from "@/_model/app.dto";
import ContentFolderModel from "@/content/_model/ContentFolderModel";
import ContentFolderListModel from "@/content/_model/ContentFolderListModel";
import ContentModel from "@/content/_model/ContentModel";
import {EntityType} from "@/entity/_model/entity.constants";
import {EngineMode} from "@/presentation/player/_model/player.constants";
import {RoutingIdentifier} from "@/router";
import ContentAppModel from "@/content_app/_model/ContentAppModel";
import {ContentAppType} from "@/content_app/_model/content_app.constants";
import audienceSessionController from "@/audience/session/_controller/AudienceSessionController";
import {SessionMode} from "@/audience/session/_model/audience_session.constants";
import {ActivityType} from "@/audience/track/_model/audience_activity.constants";
import {GamificationTargetIdentifier} from "@/gamification/_model/gamification.constants";
import {FILE_TYPE_CONFIG, FileType, IFileTypeConfig} from "@/asset_folder/_model/asset_folder.constants";
import AssetFolderListModel from "@/asset_folder/_model/AssetFolderListModel";
import FileUtil from "@/__libs/utility/FileUtil";
import ContentFileModel from "@/content_file/_model/ContentFileModel";
import fileManager from "@/_controller/FileManager";
import contentController from "@/content/_controller/ContentController";
import EntityModel from "@/entity/_model/EntityModel";
import EntityControllerFactory from "@/entity/_controller/EntityControllerFactory";
import contentFileController from "@/content_file/_controller/ContentFileController";
import contentAppController from "@/content_app/_controller/ContentAppController";
import contentFolderController from "@/content/_controller/ContentFolderController";
import {CobrowseRoomType} from "@sales-drive/audience-library/lib/cobrowse_manager/room/_model/room.constants";
import CobrowseManager from "@sales-drive/audience-library/lib/cobrowse_manager/CobrowseManager";
import playlistController from "@/playlist/_controller/PlaylistController";
import PlaylistModel from "@/playlist/_model/PlaylistModel";
import CreateEditPlaylistBox from "@/playlist/_view/CreateEditPlaylistBox.vue";
import PushNotificationsPopup from "@/_view/components/PushNotificationsPopup.vue";

@Component({
    components: {
        CreateEditPlaylistBox,
        IntroductionBox,
        FolderInfoBox,
        ShareWithAudienceBox,
        ShareWithTeamsBox,
        CreateEditContentFolderBox,
        ShareContentFolderWithTeamsBox,
        UpdateBox,
        EntityTranslationBox,
        EditContentAppBox,
        EntityInfoBox,
        EditContentFileBox,
        MainBar,
        PushNotificationsPopup}
})
export default class AppPage extends Vue {

    @Prop() private contentID?: string;

    private entity: any = new Object();
    private folder: any = new Object();
    private playlist: any = new Object()

    private shareBoxTitle: string = "";
    private shareWithMultiple: boolean = false;
    private shareFolder: boolean = false;
    private sharePlaylist: boolean = false;

    public audienceListModel: AudienceListModel = AudienceListModel.getInstance();

    public cobrowseManager: CobrowseManager = CobrowseManager.getInstance();

    get audience(): AudienceModel {
        return this.audienceListModel.globalSelState.selected!;
    }

    public mounted() {
        EventBus.$on(EventBusActions.ASK_PRESENT_CONTENT, this._askPresentContent);
        EventBus.$on(EventBusActions.ASK_PRESENT_PLAYLIST, this._askPresentPlaylist);
        EventBus.$on(EventBusActions.ASK_PREPARE_CONTENT, this._askPrepareContent);
        EventBus.$on(EventBusActions.ASK_TRAIN_CONTENT, this._askTrainContent);
        EventBus.$on(EventBusActions.ASK_EDIT_ENTITY, this._askEditEntity);
        EventBus.$on(EventBusActions.ASK_DELETE_ENTITY, this._askDeleteEntity);
        EventBus.$on(EventBusActions.ASK_COPY_ENTITY, this._askCopyEntity);
        EventBus.$on(EventBusActions.ASK_SHARE_CONTENT_WITH_AUDIENCE, this._askShareWithAudience);
        EventBus.$on(EventBusActions.ASK_SHARE_CONTENT_WITH_AUDIENCES, this._askShareWithAudiences);
        EventBus.$on(EventBusActions.ASK_SHARE_FOLDER_WITH_AUDIENCE, this._askShareFolderWithAudience);
        EventBus.$on(EventBusActions.ASK_SHARE_FOLDER_WITH_AUDIENCES, this._askShareFolderWithAudiences);
        EventBus.$on(EventBusActions.ASK_SHARE_PLAYLIST_WITH_AUDIENCE, this._askSharePlaylistWithAudience);
        EventBus.$on(EventBusActions.ASK_SHARE_PLAYLIST_WITH_AUDIENCES, this._askSharePlaylistWithAudiences);
        EventBus.$on(EventBusActions.ASK_SHARE_ENTITY_WITH_TEAMS, this._askShareWithTeams);
        EventBus.$on(EventBusActions.ASK_CREATE_EDIT_CONTENT_FOLDER, this._askCreateEditContentFolder);
        EventBus.$on(EventBusActions.ASK_CREATE_EDIT_PLAYLIST, this._askCreateEditPlaylist);
        EventBus.$on(EventBusActions.ASK_SHARE_FOLDER_WITH_TEAMS, this._askShareContentFolderWithTeams);
        EventBus.$on(EventBusActions.ASK_SHOW_ENTITY_INFO, this._askShowInfo);
        EventBus.$on(EventBusActions.ASK_SHOW_ENTITY_TRANSLATION, this._askTranslate);
        EventBus.$on(EventBusActions.ASK_UPDATE, this._askUpdate);
        EventBus.$on(EventBusActions.ASK_SHOW_FOLDER_INFO, this._askShowFolderInfo);
        EventBus.$on(EventBusActions.ASK_DELETE_FOLDER, this._askDeleteFolder);
        EventBus.$on(EventBusActions.ASK_DELETE_PLAYLIST, this._askDeletePlaylist);

        if (this.contentID) {
            const content = ContentListModel.getInstance().getEntityByID(this.contentID);
            this._askPresentContent(content!);
        } else {
            this._launchDeepLink();
        }

        const introductionBoxShown: boolean = JsonUtil.parse(LocalStorageManager.retrieveValue(LocalStorageKey.INTRODUCTION_BOX_SHOWN) as string) ? true : false;
        if ((AppUserModel.getInstance().project.tierConfig.tierType === TierType.EXPLORE || AppUserModel.getInstance().project.tierConfig.tierType === TierType.EXPLORE_PLUS)
          && !introductionBoxShown) {
            setTimeout(() => this.$bvModal.show('introduction-box'), 750);
            LocalStorageManager.storeValue(LocalStorageKey.INTRODUCTION_BOX_SHOWN, JsonUtil.stringify(true));
        }

        const pushNotificationsDenied: boolean = JsonUtil.parse(LocalStorageManager.retrieveValue(LocalStorageKey.PUSH_NOTIFICATIONS_DENIED) as string) ? true : false;
        // console.log("pushNotificationsDenied", pushNotificationsDenied , "Notification.permission", Notification.permission);
        if(!pushNotificationsDenied && Notification.permission === "default")
        {
            this.$bvModal.show('push-notifications-popup');
        }


    }

    private _launchDeepLink() {
        if (AppModel.getInstance().deepLinkParams) {
            const params: IDeepLinkParamsDto = AppModel.getInstance().deepLinkParams!;

            let audience: AudienceModel | null;
            if (params.audienceID) {
                audience = AudienceListModel.getInstance().getEntityByID(params.audienceID);
                if (audience) {
                    AudienceListModel.getInstance().globalSelState.selected = audience;
                }
            } else {
                const audiences = AudienceListModel.getInstance().list;
                AudienceListModel.getInstance().globalSelState.selected = audiences[0];
            }

            if (params.contentID) {
                const content = ContentListModel.getInstance().getEntityByID(params.contentID);
                if (!content) {
                    this.$bvModal.msgBoxOk(this.$t('ContentNotAvailableToPresent') as string, {centered: true});
                    return;
                }
                AppModel.getInstance().deepLinkParams = null;
                LocalStorageManager.deleteValue(LocalStorageKey.DEEPLINK_PARAMS);

                this._askPresentContent(content!);
            } else if (params.route) {
                this.$router.push({
                    name: params.route
                });
            } else if (params.folderID) {
                const deepLinkFolder: ContentFolderModel | null = ContentFolderListModel.getInstance().getFolderByID(params.folderID);
                if (deepLinkFolder) {
                    ContentFolderListModel.getInstance().activeContentFolder = deepLinkFolder;
                } else {
                    this.$bvModal.msgBoxOk(this.$t('ContentNotAvailableToPresent') as string, {centered: true});
                    return;
                }
            }
        }
    }

    public beforeDestroy() {
        EventBus.$off(EventBusActions.ASK_PRESENT_CONTENT, this._askPresentContent);
        EventBus.$off(EventBusActions.ASK_PREPARE_CONTENT, this._askPrepareContent);
        EventBus.$off(EventBusActions.ASK_TRAIN_CONTENT, this._askTrainContent);
        EventBus.$off(EventBusActions.ASK_EDIT_ENTITY, this._askEditEntity);
        EventBus.$off(EventBusActions.ASK_DELETE_ENTITY, this._askDeleteEntity);
        EventBus.$off(EventBusActions.ASK_COPY_ENTITY, this._askCopyEntity);
        EventBus.$off(EventBusActions.ASK_SHARE_CONTENT_WITH_AUDIENCE, this._askShareWithAudience);
        EventBus.$off(EventBusActions.ASK_SHARE_CONTENT_WITH_AUDIENCES, this._askShareWithAudiences);
        EventBus.$off(EventBusActions.ASK_SHARE_ENTITY_WITH_TEAMS, this._askShareWithTeams);
        EventBus.$off(EventBusActions.ASK_CREATE_EDIT_CONTENT_FOLDER, this._askCreateEditContentFolder);
        EventBus.$off(EventBusActions.ASK_SHOW_ENTITY_INFO, this._askShowInfo);
        EventBus.$off(EventBusActions.ASK_SHOW_ENTITY_TRANSLATION, this._askTranslate);
        EventBus.$off(EventBusActions.ASK_UPDATE, this._askUpdate);
        EventBus.$off(EventBusActions.ASK_SHOW_FOLDER_INFO, this._askShowFolderInfo);
        EventBus.$off(EventBusActions.ASK_DELETE_FOLDER, this._askDeleteFolder);
        EventBus.$off(EventBusActions.ASK_SHARE_FOLDER_WITH_TEAMS, this._askShareContentFolderWithTeams);
    }

    private _askPresentContent(content: ContentModel) {
        if (!this.audience) {
            alert("please select an audience to present to first!");
        } else {
            if (content.entityType === EntityType.CONTENT_APP) {
                const contentApp: ContentAppModel = content as ContentAppModel;

                if (contentApp.appType === ContentAppType.NATIVE_EXTERNAL) {
                    // if ((contentApp.availableRuntimes.indexOf(Runtime.IOS) >= 0 && this.$device.ios) || (contentApp.availableRuntimes.indexOf(Runtime.ANDROID) >= 0 && this.$device.android)) {
                    //   window.open(contentApp.externalLaunchUrl + this.audience.ID);
                    if (contentApp.availableRuntimes.indexOf(Runtime.IOS) >= 0 && this.$device.ios) {
                        window.open(contentApp.iosInstallUrl + '');
                    } else {
                        this.$bvModal.msgBoxOk(this.$t('AppCanOnlyRunOnMobile') as string, {centered: true});
                    }
                    return;
                }
            }
            this.$router.push({
                name: RoutingIdentifier.PLAYLIST_PLAYER,
                params: {contentID: content.ID}
            });
            contentController.startRemotePresent(content.ID);
        }
    }

    private _askPresentPlaylist(content: ContentModel) {
        if (!this.audience) {
            alert("please select an audience to present to first!");
        } else {
            if(this.$router.currentRoute.name !==RoutingIdentifier.PLAYLIST_PLAYER){
                this.$router.push({
                    name: RoutingIdentifier.PLAYLIST_PLAYER
                });
            }
        }
    }


    private _askTrainContent(content: ContentModel) {
        if (!this.audience) {
            alert("please select an audience to train for first!");
        } else {
            if (content.entityType === EntityType.PRESENTATION) {
                const engineMode: EngineMode = EngineMode.TRAIN;
                const presentationID: string = content.ID;
                this.$router.push({
                    name: RoutingIdentifier.PRESENTATION_PLAYER, params: {presentationID, engineMode}
                });
            } else if (content.entityType === EntityType.CONTENT_APP) {
                const contentApp: ContentAppModel = content as ContentAppModel;

                if (contentApp.appType === ContentAppType.NATIVE_EXTERNAL) {
                    if ((contentApp.availableRuntimes.indexOf(Runtime.IOS) >= 0 && this.$device.ios) || (contentApp.availableRuntimes.indexOf(Runtime.ANDROID) >= 0 && this.$device.android)) {
                        window.open(contentApp.externalLaunchUrl + this.audience.ID);
                    } else {
                        this.$bvModal.msgBoxOk(this.$t('AppCanOnlyRunOnMobile') as string, {centered: true});
                    }
                    return;
                }
                const engineMode: EngineMode = EngineMode.TRAIN;
                const appID: string = content.ID;
                this.$router.push({
                    name: RoutingIdentifier.CONTENT_APP_PLAYER, params: {appID, engineMode}
                });
            }
        }
    }

    private _askPrepareContent(content: ContentModel) {
        if (!this.audience) {
            alert("please select an audience to prepare for first!");
        } else {
            this.$router.push({
                name: RoutingIdentifier.CONTENT_PREPARE
            });
        }
    }

    private _askEditEntity(entity: EntityModel) {
        switch (entity.entityType) {
            case EntityType.PRESENTATION: {
                const engineMode: EngineMode = EngineMode.PRESENTATION_EDITOR;
                const presentationID: string = entity.ID;
                this.$router.push({
                    name: RoutingIdentifier.PRESENTATION_PLAYER, params: {presentationID, engineMode}
                });
                break;
            }
            case EntityType.SLIDE: {
                const engineMode: EngineMode = EngineMode.SLIDE_EDITOR;
                const slideID: string = entity.ID;
                this.$router.push({
                    name: RoutingIdentifier.PRESENTATION_PLAYER, params: {slideID, engineMode}
                });
                break;
            }
            case EntityType.CONTENT_FILE: {
                this.entity = entity;
                this.$bvModal.show('edit-content-file-box');
                break;
            }
            case EntityType.CONTENT_APP: {
                const contentApp: ContentAppModel = entity as ContentAppModel;
                const engineMode: EngineMode = EngineMode.CONTENT_APP_EDITOR;
                const appID: string = contentApp.ID;
                if (contentApp.hasCustomEditMode) {
                    this.$router.push({
                        name: RoutingIdentifier.CONTENT_APP_PLAYER, params: {appID, engineMode,}
                    });
                } else {
                    this.entity = entity;
                    this.$bvModal.show('edit-content-app-box');
                }
                break;
            }
            case EntityType.AUDIENCE: {
                this.$router.push({
                    name: RoutingIdentifier.AUDIENCE_DETAIL
                });
                break;
            }
        }
    }

    private async _askDeleteEntity(entity: EntityModel) {
        const deleteConfirmed: boolean = await this.$bvModal.msgBoxConfirm(this.$t('DashboardShowDeleteConfirmationMessage') as string, {centered: true});
        if (deleteConfirmed) {
            await EntityControllerFactory.getListController(entity.entityType).deleteEntity(entity);
        }
    }

    private async _askCopyEntity(entity: EntityModel) {
        const copyConfirmed: boolean = await this.$bvModal.msgBoxConfirm(this.$t('DashboardShowDuplicateConfirmationMessage') as string, {centered: true});
        if (copyConfirmed) {
            const copySucceeded: boolean = await EntityControllerFactory.getListController(entity.entityType).copyEntity(entity);
        }
    }

    private _askShareWithAudience(entity: EntityModel) {
        this.entity = entity;
        // this.audience = AudienceListModel.getInstance().globalSelState.selected;
        this.shareFolder = false;
        this.sharePlaylist = false;
        this.shareWithMultiple = false;
        // const shareWithMultiple:boolean = globalSelState.hasMultipleSelected && globalSelState.selecteds.length > 0;
        // this.shareBoxTitle = shareWithMultiple ? this.$t('ShareWithSelectedAudiences') as string  : this.$t('DashboardShowShareTitle', [globalSelState.selected!.displayName]) as string;
        this.shareBoxTitle = this.$t('DashboardShowShareTitle', [this.audience.displayName]) as string;

        this.$bvModal.show('share-with-audience-box');
    }

    private _askShareWithAudiences(entity: EntityModel) {
        this.entity = entity;
        // this.audience = AudienceListModel.getInstance().globalSelState.selected;
        this.shareFolder = false;
        this.sharePlaylist = false;
        this.shareWithMultiple = true;
        this.shareBoxTitle = this.$t('ShareWithSelectedAudiences') as string;
        this.$bvModal.show('share-with-audience-box');
    }

    private _askShareFolderWithAudience(folder: ContentFolderModel) {
        this.folder = folder;
        // this.audience = AudienceListModel.getInstance().globalSelState.selected;
        this.shareFolder = true;
        this.sharePlaylist = false;
        this.shareWithMultiple = false;
        // const shareWithMultiple:boolean = globalSelState.hasMultipleSelected && globalSelState.selecteds.length > 0;
        // this.shareBoxTitle = shareWithMultiple ? this.$t('ShareWithSelectedAudiences') as string  : this.$t('DashboardShowShareTitle', [globalSelState.selected!.displayName]) as string;
        this.shareBoxTitle = this.$t('DashboardShowShareTitle', [this.audience.displayName]) as string;

        this.$bvModal.show('share-with-audience-box');
    }

    private _askShareFolderWithAudiences(folder: ContentFolderModel) {
        this.folder = folder;
        // this.audience = AudienceListModel.getInstance().globalSelState.selected;
        this.shareFolder = true;
        this.sharePlaylist = false;
        this.shareWithMultiple = true;
        this.shareBoxTitle = this.$t('ShareWithSelectedAudiences') as string;
        this.$bvModal.show('share-with-audience-box');
    }

    private _askSharePlaylistWithAudience(playlist: PlaylistModel) {
        this.playlist = playlist;
        // this.audience = AudienceListModel.getInstance().globalSelState.selected;
        this.shareFolder = false;
        this.sharePlaylist = true;
        this.shareWithMultiple = false;
        // const shareWithMultiple:boolean = globalSelState.hasMultipleSelected && globalSelState.selecteds.length > 0;
        // this.shareBoxTitle = shareWithMultiple ? this.$t('ShareWithSelectedAudiences') as string  : this.$t('DashboardShowShareTitle', [globalSelState.selected!.displayName]) as string;
        this.shareBoxTitle = this.$t('DashboardShowShareTitle', [this.audience.displayName]) as string;

        this.$bvModal.show('share-with-audience-box');
    }

    private _askSharePlaylistWithAudiences(playlist: PlaylistModel) {
        this.playlist = playlist;
        // this.audience = AudienceListModel.getInstance().globalSelState.selected;
        this.shareFolder = false;
        this.sharePlaylist = true;
        this.shareWithMultiple = true;
        this.shareBoxTitle = this.$t('ShareWithSelectedAudiences') as string;
        this.$bvModal.show('share-with-audience-box');
    }



    private _onShareWithAudienceFinish() {
        this.$bvModal.hide('share-with-audience-box');
    }

    private _askShareWithTeams(entity: EntityModel) {
        this.entity = entity;
        this.$bvModal.show('share-with-teams-box');
    }

    private _onShareWithTeamsFinish() {
        this.$bvModal.hide('share-with-teams-box');
    }

    private _askShowInfo(entity: EntityModel) {
        this.entity = entity;
        this.$bvModal.show('entity-info-box');
    }

    private _onShowInfoClose() {
        if (this.entity.hasChanges) {
            contentController.saveBody(this.entity);
        }
    }

    private _onShowInfoFinish() {
        this.$bvModal.hide('entity-info-box');
    }

    private onEditContentFileBoxClose() {
        if ((this.entity as ContentFileModel).hasChanges) {
            contentFileController.saveBody(this.entity);
        }
    }

    private onEditContentAppBoxClose() {
        if ((this.entity as ContentAppModel).hasChanges) {
            contentAppController.saveBody(this.entity);
        }
    }

    private _askCreateEditContentFolder(p_folder: ContentFolderModel | null) {
        this.folder = p_folder;
        this.$bvModal.show('create-edit-folder-box');
    }



    private _askShareContentFolderWithTeams(p_folder: ContentFolderModel | null) {
        this.folder = p_folder;
        this.$bvModal.show('share-folder-with-teams-box');
    }

    private _onCreateEditContentFolderBoxFinish() {
        this.$bvModal.hide('create-edit-folder-box');
    }




    private _onShareContentFolderWithTeamsBoxFinish() {
        this.$bvModal.hide('share-folder-with-teams-box');
    }

    private onCreateEditContentFolderBoxClose() {
        if (this.folder.hasChanges) {
            contentFolderController.saveContentFolder(this.folder);
        }
    }

    private onShareContentFolderWithTeamsBoxClose() {
        if (this.folder.hasChanges) {
            contentFolderController.saveContentFolder(this.folder);
        }
    }

    //playlist
    private _askCreateEditPlaylist(p_playlist: PlaylistModel | null) {
        this.playlist = p_playlist;
        this.$bvModal.show('create-edit-playlist-box');
    }

    private onCreateEditPlaylistBoxClose() {
        if (this.playlist.hasChanges) {
            playlistController.savePlaylist(this.playlist);
        }
    }
    private _onCreateEditPlaylistBoxFinish() {
        this.$bvModal.hide('create-edit-playlist-box');
        EventBus.$emit(EventBusActions.CREATE_PLAYLIST_FINISHED, this.playlist);
    }

    private _askUpdate() {
        // only show update popup if not presenting
        if (this.$route.name !== RoutingIdentifier.PRESENTATION_PLAYER && this.$route.name !== RoutingIdentifier.CONTENT_APP_PLAYER && this.$route.name !== RoutingIdentifier.CONTENT_FILE_PLAYER) {
            this.$bvModal.show('update-box');
        }
    }

    @Watch('audience', {immediate: true, deep: true})
    private async _onAudienceChange(newAudience: AudienceModel | null, oldAudience: AudienceModel | null) {
        if (this.cobrowseManager.isConnected && newAudience) {
            const roomIdentifier: string = `${AppUserModel.getInstance().project.identifier}:${CobrowseRoomType.PORTAL}:${newAudience.ID}`;
            //if you are connected to this audiences room
            this.cobrowseManager.activeRoom = this.cobrowseManager.getRoom(roomIdentifier);
        }
    }

    private _askTranslate(entity: EntityModel) {
        this.entity = entity;
        this.$bvModal.show('entity-translation-box');
    }

    private _onTranslateFinish() {
        this.$bvModal.hide('entity-translation-box');
    }

    private _askShowFolderInfo(folder: ContentFolderModel) {
        this.folder = folder;
        this.$bvModal.show('folder-info-box');
    }

    private _onShowFolderInfoFinish() {
        this.$bvModal.hide('folder-info-box');
    }

    private async _askDeleteFolder(folder: ContentFolderModel) {
        const deleteConfirmed: boolean = await this.$bvModal.msgBoxConfirm(this.$t("SureDeleteFolder") as string, {centered: true});
        if (deleteConfirmed) {
            await contentFolderController.deleteFolder(folder);
        }
    }

    private async _askDeletePlaylist(p_playlist: PlaylistModel) {
        const deleteConfirmed: boolean = await this.$bvModal.msgBoxConfirm(this.$t("SureDeletePlaylist") as string, {centered: true});
        if (deleteConfirmed) {
            await playlistController.deletePlaylist(p_playlist);
        }
    }

}
</script>
