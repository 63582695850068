import {IAppUserDto} from "@/_model/app.dto";
import Model from "@/__libs/_model/Model";
import {ITeamOutlineDto, IUserDto} from "@/team/_model/team.dto";
import {UserOrigin, UserStatus} from "@/team/_model/team.constants";
import {DtoType} from "@/_model/app.constants";
import {IModelDto} from "@/__libs/_model/model.dto";
import {IContentFolderDto} from "@/content/_model/content.dto";
import {IProjectSessionDto} from "@/project/_model/project.dto";
import ProjectModel from "@/project/_model/ProjectModel";
import {
    IGamificationLeaderBoardUserDto,
    IGamificationTargetValueDto,
    IGamificationUserStateDto
} from "@/gamification/_model/gamification.dto";


class AppUserModel extends Model implements IAppUserDto, IUserDto {

    public ID: number = 0;
    public roleID: number = 0;
    public displayName: string = "";
    public createdDate: Date = new Date();
    public createdByUserID: number = 0;
    public langCode: string = "en";
    public email: string = "";
    public avatarFileUri: string = "";
    public teamID: number = 0;
    public projectID: number = 0;
    public userOrigin: UserOrigin = UserOrigin.STATIC;
    public status: UserStatus = UserStatus.UNVERIFIED;
    public firstName: string = "";
    public lastName: string = "";
    public telNr: string = "";
    public mobileNr: string = "";
    public faxNr: string = "";
    public department: string = "";
    public jobTitle: string = "";
    public address: string = "";
    public website: string = "";
    public signature: string = "";
    public teamName: string = "";
    public roleIdentifier: string = "";

    public sessionID: number = 0;
    public rights: string[] = [];
    public shareableTeams: ITeamOutlineDto[] = [];
    public contentFolders: IContentFolderDto[] = [];

    public project: ProjectModel = new ProjectModel();

    public hasChanges: boolean = false;
    public isAuthenticated: boolean = false;
    public highlightShown: boolean = false;

    public crmProviders: any = {};

    public gamificationState: IGamificationUserStateDto = {
        userID: 0,
        coins: 0,
        ranking: 0
    };
    public gamificationTargets: IGamificationTargetValueDto[] = [];
    public gamificationLeaderboard: IGamificationLeaderBoardUserDto[] = [];

    public hasNotificationPermission:boolean = Notification.permission === "granted";


    //---------------------------------
    // Singleton Constructor
    //---------------------------------

    private static _instance: AppUserModel;

    constructor() {
        super();
        if (!AppUserModel._instance) {
            AppUserModel._instance = this;
        }
    }

    public static getInstance(): AppUserModel {
        if (!AppUserModel._instance) {
            new AppUserModel();
        }
        return AppUserModel._instance;
    }

    //untill typescript has proper reflection, we will have to do it this way:
    public mapToDto(p_dtoType: DtoType): IModelDto {
        if (p_dtoType === DtoType.BODY) {
            const dto: IUserDto = {
                ID: this.ID,
                displayName: this.displayName,
                teamID: this.teamID,
                roleID: this.roleID,
                langCode: this.langCode,
                firstName: this.firstName,
                lastName: this.lastName,
                telNr: this.telNr,
                mobileNr: this.mobileNr,
                faxNr: this.faxNr,
                department: this.department,
                jobTitle: this.jobTitle,
                avatarFileUri: this.avatarFileUri,
                address: this.address,
                website: this.website,
                signature: this.signature,
            };
            return dto;
        } else {
            return super.mapToDto(p_dtoType);
        }
    }

}

export default AppUserModel;
