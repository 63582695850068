<template>
    <div class="app-user-summary box box--full-height box--scrollable" v-if="appUser">

        <div class="app-user-summary__content">
            <AAProfilePicture :name="appUser.displayName"
                              :imageUri="appUser.avatarFileUri"
                              :editable="true"
                              :huge="true"
                              class="mb-4"
                              v-on:click.native="_onOpenAssetPickerBtnClick"></AAProfilePicture>
            <h2 class="mb-2 title">{{ appUser.displayName }}</h2>
            <h5 class="mb-5">{{ appUser.email }}</h5>
            <div class="mb-5">
                {{ appUser.roleIdentifier }}<br/><i><small>{{ $t('AppUserInTeam') }}</small></i><br/>{{
                    appUser.teamName
                }}<br/><i><small>({{ appVersion }})</small></i>
            </div>
            <b-button variant="outline-secondary" @click="_onLogoutBtnClick">{{ $t('MainMenuLogout') }}</b-button>

            <b-button v-if="hasNotificationPermission" variant="outline-secondary"
                      @click="_onDisablePushNotificationsBtnClick">{{ $t('DisablePushNotifications') }}
            </b-button>
            <b-button v-else variant="outline-secondary" @click="_onEnablePushNotificationsBtnClick">
                {{ $t('EnablePushNotifications') }}
            </b-button>

        </div>

        <b-modal id="app-user-asset-picker-box" size="xl" :title="$t('PickUserAvatarOrUploadNewOne')" hide-footer>
            <AssetFoldersPicker :selectedFileUri="appUser.avatarFileUri"
                                :allowedTargetType="avatarAllowedTargetType"
                                :allowedFileTypes="avatarAllowedFileTypes"
                                v-on:onAssetFilePicked="_onAssetFilePicked"></AssetFoldersPicker>
        </b-modal>

    </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import AssetFileModel from "@/asset_folder/_model/AssetFileModel";
import AssetFoldersPicker from "@/asset_folder/_view/AssetFoldersPicker.vue";
import {FileTargetType, FileType} from "@/asset_folder/_model/asset_folder.constants";
import {RoutingIdentifier} from "@/router";
import AppUserModel from "@/project/user/_model/AppUserModel";
import AAProfilePicture from "@/_view/components/AAProfilePicture.vue";
import AppModel from "@/_model/AppModel";
import serviceWorkerController from "@/_controller/ServiceWorkerController";
import Util from "@/__libs/utility/Util";
import appUserController from "@/project/user/_controller/AppUserController";


@Component({
    components: {
        AAProfilePicture, AssetFoldersPicker
    }
})
export default class AppUserSummary extends Vue
{

    public avatarAllowedTargetType:FileTargetType = FileTargetType.USER_AVATAR;
    public avatarAllowedFileTypes:FileType[] = [FileType.IMAGE];

    public appUser:AppUserModel = AppUserModel.getInstance();
    public appVersion:string | undefined = AppModel.getInstance().version;


    public get hasNotificationPermission():boolean
    {
        return this.appUser.hasNotificationPermission;
    }

    private async _onLogoutBtnClick()
    {
        const logoutConfirmed:boolean = await this.$bvModal.msgBoxConfirm(this.$t('ConfirmLogOut') as string, {centered: true});
        if (logoutConfirmed)
        {
            await this.$router.push({name: RoutingIdentifier.LOGOUT});
        }
    }

    private _onOpenAssetPickerBtnClick(p_e:Event)
    {
        this.$bvModal.show('app-user-asset-picker-box');
    }

    private _onAssetFilePicked(p_assetFile:AssetFileModel)
    {
        this.$bvModal.hide('app-user-asset-picker-box');
        if (p_assetFile && this.appUser)
        {
            this.appUser.avatarFileUri = p_assetFile.fileURI;
            this.appUser.hasChanges = true;
        }
    }

    private async _onEnablePushNotificationsBtnClick(p_e:Event)
    {
        await appUserController.requestPushNotifications();
    }

    private async _onDisablePushNotificationsBtnClick(p_e:Event)
    {
        await appUserController.disablePushNotifications();
    }
}
</script>

